import * as React from "react";
// import HcpLayout from "../components/why-wakix/layout";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import { TfiAngleRight } from "react-icons/tfi";
import { Col, Row } from "react-grid-system";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useDeviceSize from "../hooks/use-device-size";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazy-load";
import bannerImg from "../images/why-wakix-landing-page/hero-why-wakix.webp";
import bannerImgMobile from "../images/why-wakix-landing-page/hero-why-wakix-mobile.webp";
import personalStoryBg from "../images/why-wakix-landing-page/why-wakix-personal-stories.webp";
import personalStoryBgMobile from "../images/why-wakix-landing-page/why-wakix-personal-stories-mob-img.webp";
import SocialIconBlock from "../components/why-wakix/socialIconBlock";
import StickySupport from "../components/why-wakix/sticky-support";
import PersonalVideo from "../components/cards/personalVideo";
import TaraThumb from "../images/why-wakix-landing-page/tara-video-thumbnail.webp";
import CelinaThumb from "../images/why-wakix-landing-page/celina-video-thumbnail.webp";
import tipThumbnailImg from "../images/why-wakix-landing-page/tips-thumbnail.webp";
import wakeUpTo from "../images/why-wakix-landing-page/WUTW-Italic-glow.png";
import wakeUpToMobile from "../images/why-wakix-landing-page/WUTW-Italic-mobile.png";
import "./index.scss";
import "./why-wakix.scss";

const WhyWakixPage = () => {
  const deviceSizes = useDeviceSize();
  return (
    <HcpLayout
      pageClass="hcp-main hcp-why-wakix"
      jobCode="US-WAK-2400433/Sep 2024"
      copyRightYear="2024"
    >
      <div className="hero-wrapper row-full">
        <div className="hero-content-wrapper">
          <div className="hero-banner inner bg-cover about">
            {deviceSizes?.smUp ? (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={bannerImg}
                  alt="Tara, Living with narcolepsy, Taking WAKIX since 2021"
                />
              </LazyLoad>
            ) : (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={bannerImgMobile}
                  alt="Tara, Living with narcolepsy, Taking WAKIX since 2021"
                />
              </LazyLoad>
            )}
            <div className="hero-banner__content">
              <div className="centered-content">
                <div className="image-wrapper">
                  <StaticImage
                    className="home-hero-logo"
                    src="../images/why-wakix-landing-page/home-hero-logo.webp"
                    alt="Tara living with Narcolepsy"
                    placeholder="none"
                    style={{ transition: "opacity 0.5s" }}
                    imgStyle={{
                      width: "100%",
                      height: "auto",
                      opacity: "1",
                    }}
                    quality={95}
                    layout="constrained"
                  />
                </div>

                <div className="wake-up-wrapper">
                  {deviceSizes?.mdDown ? (
                    <img
                      src={wakeUpToMobile}
                      className="hero-wakeup-text"
                      alt="Wake Up To WAKIX"
                      width="332"
                      height="313"
                    />
                  ) : (
                    <img
                      src={wakeUpTo}
                      className="hero-wakeup-text"
                      alt="Wake Up To WAKIX"
                      width="355"
                      height="264"
                    />
                  )}
                </div>

                <div className="name-wrap">
                  <StaticImage
                    src="../images/why-wakix-landing-page/tara-name-title.webp"
                    alt="Tara, Living with narcolepsy, Taking WAKIX since 2020"
                    quality={95}
                    layout="constrained"
                    placeholder="none"
                    style={{ transition: "opacity 0.5s" }}
                    imgStyle={{ opacity: 1 }}
                  />
                </div>

                <div className="btn-wrapper">
                  <div className="headline-cta desktop-version">
                    <a
                      href="https://wakix.com/about-wakix"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Why WAKIX
                      <TfiAngleRight />
                    </a>
                  </div>
                  <div className="mobile-version">
                    <a
                      href="https://wakix.com/about-wakix"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Why WAKIX
                      <TfiAngleRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StickySupport />

          <div className="hero-bottom-info">
            <div className="centered-content">
              <div className="block-width--990">
                <h3 className="font-25 asap">
                  FDA approved to treat excessive daytime sleepiness (EDS) or
                  cataplexy in adults with narcolepsy and to treat EDS in
                  children 6 years of age and older with narcolepsy
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="block-about-wakix">
        <div className="centered-content">
          <div className="block-title">
            <h1 className="green font-40">Why WAKIX?</h1>
          </div>

          <div className="section-card_items">
            <ul className="icon-list">
              <li>
                <div className="icon">
                  {deviceSizes?.smUp ? (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-not-a-stimulant.svg"
                      alt="Not a stimulant icon"
                      placeholder="none"
                      quality={95}
                      layout="constrained"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-not-a-stimulant.svg"
                      alt="Not a stimulant icon"
                      placeholder="none"
                      quality={95}
                      layout="constrained"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  )}
                </div>
                <div className="text">
                  <h3>Not a stimulant</h3>
                  <span>
                    WAKIX is <strong className="italic">not</strong>
                    <strong> a stimulant</strong>, so the way your body feels
                    when taking WAKIX may be different from medications you have
                    taken in the past.
                  </span>
                </div>
              </li>

              <li>
                <div className="icon">
                  {deviceSizes?.smUp ? (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-once-daily.svg"
                      alt="Once daily calendar icon"
                      quality={95}
                      layout="constrained"
                      placeholder="none"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-once-daily.svg"
                      alt="Once daily calendar icon"
                      quality={95}
                      layout="constrained"
                      placeholder="none"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  )}
                </div>
                <div className="text">
                  <h3>Once-daily tablet medication</h3>
                  <span>
                    WAKIX tablets are taken{" "}
                    <strong>once daily in the morning</strong> as soon as you
                    wake up.
                  </span>
                </div>
              </li>

              <li>
                <div className="icon">
                  {deviceSizes?.smUp ? (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-first-class.svg"
                      alt="First-of-its-kind icon"
                      placeholder="none"
                      quality={95}
                      layout="constrained"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-first-class.svg"
                      alt="First-of-its-kind icon"
                      placeholder="none"
                      quality={95}
                      layout="constrained"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  )}
                </div>
                <div className="text">
                  <h3>First-of-its-kind</h3>
                  <span>
                    WAKIX is a <strong>first-of-its-kind medication</strong>{" "}
                    that increases <strong>histamine</strong> levels in the
                    brain.
                  </span>
                </div>
              </li>

              <li>
                <div className="icon">
                  {deviceSizes?.smUp ? (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-not-controlled.svg"
                      alt="Not a controlled substance pill bottle icon"
                      placeholder="none"
                      quality={95}
                      layout="constrained"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  ) : (
                    <StaticImage
                      src="../images/why-wakix-landing-page/why-wakix-icons-not-controlled.svg"
                      alt="Not a controlled substance pill bottle icon"
                      placeholder="none"
                      quality={95}
                      layout="constrained"
                      style={{ transition: "opacity 0.5s" }}
                      imgStyle={{ opacity: 1 }}
                    />
                  )}
                </div>

                <div className="text">
                  <h3>
                    Not a{" "}
                    <span className="tooltip green">
                      controlled substance
                      <span className="tooltip-text">
                        A drug or chemical that is regulated by the government
                        based on its potential for abuse and dependence.
                      </span>
                    </span>
                  </h3>
                  <span>
                    WAKIX is the first and only <nobr>FDA-approved</nobr>{" "}
                    treatment for people with narcolepsy that is{" "}
                    <strong className="italic">not</strong>
                    <strong> a controlled substance</strong>.
                  </span>
                </div>
              </li>
            </ul>
            <h2>
              Talk to your healthcare provider to see if WAKIX could be right
              for you
            </h2>
            <br />
          </div>

          <div className="btn-wrap">
            <a
              href="/pdf/WAKIX-educational-information-for-patients.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-primary--md"
            >
              Learn more about WAKIX
              <TfiAngleRight />
            </a>
          </div>
        </div>
      </section>

      <section className="block-stories stories-rows-wrap">
        <div className="centered-content">
          <div className="block-title">
            <h2 className="green font-40">
              Hear What Real People Living With Narcolepsy Have to Say About
              WAKIX
            </h2>
          </div>

          <div className="story-row">
            <Row justify="between">
              <Col lg={5} xl={4.25} order={{ xs: 2, lg: 1 }}>
                <div className="text" id="personal-story-tara">
                  <AnchorLink href="#personal-story-tara" />
                  <h2>Meet Tara</h2>
                  <h3>
                    Recent college graduate, healthcare worker, dog mom,
                    narcolepsy advocate <br className="mobile-only" />
                    Taking WAKIX since 2020
                  </h3>
                  <p>
                    After receiving her narcolepsy diagnosis, Tara felt both
                    relieved and nervous. Hear how she learned about WAKIX
                    through her online support groups and social media and what
                    she noticed about her excessive daytime sleepiness as time
                    went on.
                  </p>
                </div>
              </Col>

              <Col
                lg={6}
                xl={7}
                order={{ xs: 1, lg: 2 }}
                className="two-column__video"
              >
                <div className="card-video-wrap">
                  <PersonalVideo
                    videoId="1006599816"
                    videoIdParam="7a898e7a4f"
                    videoThumbnail={TaraThumb}
                    altText={
                      "Video thumbnail of Tara, a real person with narcolepsy with cataplexy taking WAKIX"
                    }
                    customTagText="Tara video"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="story-row">
            <Row justify="between">
              <Col lg={5} xl={4.25} order={{ xs: 2, lg: 1 }}>
                <div className="text" id="personal-story-celina">
                  <AnchorLink href="#personal-story-celina" />
                  <h2>Meet Celina</h2>
                  <h3>
                    Healthcare worker, graduate student, <nobr>dog mom</nobr>,
                    baker <br />
                    Taking WAKIX since 2020
                  </h3>
                  <p>
                    When Celina found herself fighting to keep her eyes open at
                    school, she knew it was time to seek help. Hear why Celina
                    was interested in learning more about WAKIX and how she
                    realized WAKIX was working for her.
                  </p>
                </div>
              </Col>

              <Col
                lg={6}
                xl={7}
                order={{ xs: 1, lg: 2 }}
                className="two-column__video"
              >
                <div className="card-video-wrap">
                  <PersonalVideo
                    videoId="1006603588"
                    videoIdParam="023aa4bf0c"
                    videoThumbnail={CelinaThumb}
                    altText={
                      "Video thumbnail of Celina, a real person with narcolepsy taking WAKIX"
                    }
                    customTagText="Celina video"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="block-tips">
        <div className="centered-content">
          <div className="block-tips__wrap">
            <div className="block-title">
              <h2 className="green font-40">
                Tips for College Students Living With Narcolepsy
              </h2>
            </div>
            <div className="card-info">
              <div className="card-info__media">
                <img
                  src={tipThumbnailImg}
                  loading="lazy"
                  alt="Tips for College Students Living With Narcolepsy brochure thumbnail"
                />
              </div>
              <div className="card-info__content">
                <div className="btn-wrap btn-wrap-custom">
                  <a
                    href="/pdf/WAKIX-tips-college-students-narcolepsy.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-primary--md"
                  >
                    Download now
                    <TfiAngleRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-block two-columns banner-block--alt no-pb no-pt">
        <div className="col bg-img">
          <div className="inner bg-cover">
            {deviceSizes?.smUp ? (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={personalStoryBg}
                  alt="Tara personal story"
                  loading="lazy"
                />
              </LazyLoad>
            ) : (
              <LazyLoad offset={300} debounce={false}>
                <img
                  src={personalStoryBgMobile}
                  alt="Tara personal story"
                  loading="lazy"
                />
              </LazyLoad>
            )}
            <div className="two-col__content cout-left-spacer">
              <div className="two-col__title text-white has-textShadow">
                <h4>
                  Hear what real
                  <br /> people living with <br /> narcolepsy have to <br />
                  say about WAKIX
                </h4>
              </div>
              <div className="btn-wrap top-spacer">
                <a
                  href="https://wakix.com/wakix-personal-stories"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-primary btn-primary--md"
                >
                  See their stories
                  <TfiAngleRight />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col bg-green">
          <div
            className="inner cta-flt-left align-center-desk-only align-end"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="inner__content">
              <StaticImage
                src="../images/icons/icon-copay-zero.svg"
                placeholder="blurred"
                alt="$0 WAKIX copay program icon"
                className="zero-copay"
                width={128}
                height={114}
              />
              <div className="text">
                <h3 className="h3-spacer asap">
                  Copay program for eligible patients*
                </h3>
                <div className="btn-wrap">
                  <a
                    href="https://wakix.com/wakix-for-you-support-program"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="btn btn-primary btn-primary--md"
                  >
                    Learn more
                    <TfiAngleRight />
                  </a>
                </div>
              </div>
            </div>
            <div className="block-title">
              <p className="footnotes footnotes-symbols top-spacer no-spacer-mrg">
                *This offer is valid only for patients who have commercial
                (nongovernment-funded) insurance.
              </p>
              <p className="footnotes footnotes-symbols second no-spacer">
                Additional terms and conditions apply.
              </p>
            </div>
          </div>
        </div>
      </section>

      <SocialIconBlock />
    </HcpLayout>
  );
};

export default WhyWakixPage;

export const Head = () => (
  <Seo title="WAKIX® (pitolisant)" description="" keywords="" />
);
