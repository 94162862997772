import React from "react";
import supportDoctorIcon from "../../../images/HCP Locator Icon.svg";


function StickySupport() {
  return (
    <div className="sticky-support">
      <span className="sp-icon">
        <img
          src={supportDoctorIcon}
          alt="Sleep specialist for narcolepsy icon"
        />

        <a
          href="https://wakix.com/resources-and-support/find-a-healthcare-provider"
          className="text"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find a healthcare provider near you
        </a>
      </span>
    </div>
  );
}

export default StickySupport;
